<template>
  <hb-dialog size="sm" @click.stop.prevent="() => {}">
    <hb-dialog-header :after-close="afterClose">
      {{ $t('label.under_development') }}
    </hb-dialog-header>
    <hb-dialog-body>
      <p>
        {{ $t('label.under_development_text') }}
      </p>
    </hb-dialog-body>
    <hb-dialog-footer no-border>
      <hb-btn
        theme="secondary"
        size="block-lg"
        @click="dialog.close(afterClose)"
      >
        <span>{{ $t('label.ok') }}</span>
      </hb-btn>
    </hb-dialog-footer>
  </hb-dialog>
</template>

<script lang="ts">
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogFooter from '~/components/base/dialog/HbDialogFooter.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import { useDialog } from '~/composables'
import { DialogMixin } from '~/mixins'

export default {
  name: 'UnderDevelopment',
  components: {
    HbDialogHeader,
    HbDialogBody,
    HbBtn,
    HbDialogFooter,
    HbDialog,
  },
  mixins: [DialogMixin],
  setup(props) {
    const dialog = useDialog(props)

    return {
      dialog,
    }
  },
}
</script>

<style scoped lang="scss">
p {
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 28px;
}
</style>
