<template>
  <div
    class="location-search"
    :class="{
      'location-search--small': isSmall,
      'location-search--icon-only': isIconOnly,
    }"
  >
    <hb-autocomplete
      v-model="model"
      :placeholder="
        placeholder
          ? placeholder
          : isMobile
          ? $t('search.location_placeholder_mobile')
          : $t('search.location_placeholder')
      "
      option-icon-name="pin"
      :options="options"
      :loading="isLoading"
      :is-dirty="isDirty"
      :is-small="isSmall"
      :is-icon-only="isIconOnly"
      :top-level="topLevel"
      @search="onSearch"
      @select="goToSearch"
      @clear="onClear"
      @closed="errorMsg = null"
    >
      <template v-if="showMyLocation" #prepend>
        <hb-autocomplete-option
          icon-name="pin-underlined"
          pinned
          :error="errorMsg"
          @click="getMyLocation"
        >
          {{ $t('search.my_current_location') }}
        </hb-autocomplete-option>
      </template>
    </hb-autocomplete>
    <hb-btn
      theme="icon"
      size="lg"
      class="hb-btn-icon--bordered bg-white"
      aria-label="Search"
      @click="goToSearch(model)"
    >
      <nuxt-icon name="search" filled />
    </hb-btn>
  </div>
</template>

<script lang="ts">
import { isObject, isString, ModelMixin, useGeolocation, useModel } from 'wue'
import { isClient } from '@vueuse/core'
import { computed, ref } from 'vue'
import { debounce, kebabCase } from 'lodash-es'
import HbAutocomplete from '~/components/base/form/HbAutocomplete.vue'
import HbAutocompleteOption from '~/components/base/form/HbAutocompleteOption.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { goToSearchPage, LocationSearchHistory } from '~/utils'
import type { OpenStreetResponse } from '~/composables'
import {
  AppEvents,
  isMultiPolygon,
  isPolygon,
  isPostalCode,
  useEmit,
  useGooglePlaceAutocomplete,
  useLocationSearchState,
  useOpenStreetApi,
  useViewTools,
} from '~/composables'
import type { HbAcOption, LabelValue } from '~/types'
import { usePropertyStore } from '~/stores'
import { openStreetResponsesToRegions } from '~/modules/search/utils/location.utils'
import { showUnderDevelopment, useI18n } from '#imports'

const getOptionsFromStorage = () => {
  if (isClient) {
    return LocationSearchHistory.getAll()
      .reverse()
      .map((item) => ({ label: item.label, value: item.osmId }))
  }

  return []
}

export default {
  name: 'LocationSearch',
  components: { HbBtn, HbAutocompleteOption, HbAutocomplete },
  mixins: [ModelMixin],
  props: {
    isSmall: { type: Boolean, default: false },
    isIconOnly: { type: Boolean, default: false },
    topLevel: { type: Boolean, default: false },
    placeholder: { type: String, default: () => null },
  },
  setup(props, ctx) {
    const { t } = useI18n()
    const propsRef = computed(() => ({ ...props }))
    const locationState = useLocationSearchState()
    const modelCtx = useModel<number>(propsRef, ctx, {
      initialValue: locationState.value?.label || null,
    })
    const options = ref<HbAcOption[]>(getOptionsFromStorage())
    const isDirty = ref(false)
    const searchValue = ref<string>(locationState.value?.label || null)
    const showMyLocation = computed(() => !searchValue.value)
    const { errorMsg, getCurrentPosition } = useGeolocation()
    const { search: openStreetSearch } = useOpenStreetApi()
    const { isLoading, search: googleSearch } = useGooglePlaceAutocomplete(null)
    const { api: propertyApi } = usePropertyStore()
    const storePolygons = async (result: OpenStreetResponse[]) => {
      try {
        const regions = openStreetResponsesToRegions(result)

        if (regions.length) {
          await propertyApi.regionPolygonStore({
            regionPolygonStore: {
              regions,
            },
          })
        }
      } catch (e) {
        //
      }
    }
    const runSearch = async (query: string) => {
      const result = await googleSearch(query)
      const optionsRaw: LabelValue[] = result?.map((item) => ({
        label: item.description,
        value: item.place_id,
      }))

      if (isPostalCode(query)) {
        optionsRaw.unshift({
          label: t('search.search_by_postal_code', [query]),
          value: `postal-code:${query}`,
        })
      }

      options.value = optionsRaw
    }
    const debouncedSearch = debounce((query: string) => {
      isDirty.value = !!query
      runSearch(query)
    }, 450)
    const getMyLocation = () => {
      getCurrentPosition(async (position) => {
        const result = await openStreetSearch(
          `${position.coords.latitude},${position.coords.longitude}`
        )
        const item: OpenStreetResponse =
          result?.[0] || ({} as OpenStreetResponse)

        if (isObject(item?.address)) {
          options.value = [
            {
              label: item.display_name,
              value: item.osm_id,
            },
          ]
        }
      })
    }
    const onSearch = (value: string) => {
      if (value) {
        debouncedSearch(value)
      } else {
        options.value = getOptionsFromStorage()
      }

      searchValue.value = value
    }
    const onClear = () => {
      searchValue.value = null
      options.value = getOptionsFromStorage()
    }
    const beforeOpen = (open: () => void) => {
      showUnderDevelopment(true, () => {
        open()
      })
    }
    const goToSearch = async (value: string | number) => {
      if (!value) {
        goToSearchPage()
        return
      }

      const label = options.value?.find((item) => item?.value === value)?.label
      const slug = kebabCase(searchValue.value)
      const isPostalCode = isString(value)
        ? value.includes('postal-code:')
        : false
      const isExcluded = isString(value) ? value.includes('exclude:') : false
      const postalCode = isPostalCode ? (value as string).split(':')[1] : null
      let foundedItem: OpenStreetResponse = null

      if (!isPostalCode && isString(label) && label) {
        try {
          isLoading.value = true
          const result = ((await openStreetSearch(label)) || []).filter(
            (item) =>
              !!item.osm_id &&
              item.geojson &&
              (isPolygon(item.geojson) || isMultiPolygon(item.geojson))
          )

          foundedItem = result?.[0] || null

          if (result?.length) {
            await storePolygons(result)
          }
        } finally {
          isLoading.value = false
        }
      }

      if (value && slug) {
        LocationSearchHistory.set({
          osmId: value as number,
          label,
          slug,
        })
      }

      if (locationState.value?.value === value) {
        useEmit(AppEvents.SearchRecenter)
      } else {
        locationState.value = {
          label,
          value,
        }
      }

      goToSearchPage(
        isExcluded
          ? undefined
          : isPostalCode
          ? (value as number)
          : foundedItem?.osm_id || undefined,
        isPostalCode ? postalCode : searchValue.value
      )
    }

    return {
      getMyLocation,
      errorMsg,
      options,
      isLoading,
      isDirty,
      showMyLocation,
      beforeOpen,
      onSearch,
      onClear,
      goToSearch,
      ...modelCtx,
      ...useViewTools(),
    }
  },
}
</script>

<style scoped lang="scss">
.location-search {
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;

  @include mobile {
    gap: 0;
  }

  .hb-autocomplete {
    flex: 1;
  }

  button {
    padding: 16px 22px;
    margin-left: 10px;

    @include mobile {
      margin-left: 0;
    }
  }

  &--small {
    gap: 0;

    button {
      padding: 0;
      position: absolute;
      top: 11px;
      right: 20px;
      font-size: 20px;
      border: 0;
      pointer-events: none;

      @include mobile {
        top: 17px;
      }
    }
  }

  &--icon-only {
    button {
      top: 0;
      right: 0;
      line-height: 1;
    }
  }
}
</style>
