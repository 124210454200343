<template>
  <client-only>
    <div :class="rootClasses" v-on="rootListeners">
      <div
        v-if="isTablet"
        class="autocomplete-fake-button"
        @click="isOpen = !isOpen"
      >
        <span v-if="searchValue"
          ><strong>{{ searchValue }}</strong></span
        >
        <span v-else> {{ placeholder }} </span>
      </div>
      <bottom-sheet
        ref="bottomSheet"
        v-model="isOpen"
        only-header-swipe
        class="bottom-sheet-autocomplete"
      >
        <template #header>
          <hb-autocomplete-input
            :ref="onMobileSearchInit"
            v-model="searchValue"
            :bordered="isOpen && isTablet"
            :is-mobile="isOpen && isTablet"
            :placeholder="placeholder"
            :loading="loading"
            auto-focus
            @clear="emitClear"
            @search="emitSearch"
          />
          <hb-input-error-message
            v-if="showError"
            :name="name"
            :errors="errors"
          />
        </template>

        <teleport :disabled="isTablet" to="body">
          <transition name="slide-fade">
            <hb-autocomplete-dropdown
              ref="dropdownEl"
              v-model="isOpen"
              :target-style="targetStyle"
              :dropdown-classes="dropdownClasses"
              :is-dirty="isDirty"
              :option-icon-name="optionIconName"
              :options="options"
              :loading="loading"
              @select="select"
            >
              <template #prepend>
                <slot name="prepend"></slot>
              </template>
            </hb-autocomplete-dropdown>
          </transition>
        </teleport>
      </bottom-sheet>
    </div>
  </client-only>
</template>

<script lang="ts">
import { BaseInputMixin, useBaseInput, usePositioning } from 'wue'
import { onClickOutside } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useViewTools } from '#imports'
import BottomSheet from '~/components/BottomSheet.vue'
import HbAutocompleteDropdown from '~/components/base/form/HbAutocompleteDropdown.vue'
import HbAutocompleteInput from '~/components/base/form/HbAutocompleteInput.vue'
import type { HbAcOption } from '~/types'
import HbInputErrorMessage from '~/components/base/form/HbInputErrorMessage.vue'

export default {
  name: 'HbAutocomplete',
  components: {
    HbInputErrorMessage,
    HbAutocompleteInput,
    HbAutocompleteDropdown,
    BottomSheet,
  },
  mixins: [BaseInputMixin],
  props: {
    placeholder: { type: String, default: () => null },
    optionIconName: { type: String, default: () => null },
    uniqDropdownClass: { type: String, default: () => null },
    options: { type: Array as () => HbAcOption[], default: () => [] },
    loading: { type: Boolean, default: () => false },
    isDirty: { type: Boolean, default: () => false },
    isSmall: { type: Boolean, default: () => false },
    isIconOnly: { type: Boolean, default: () => false },
    topLevel: { type: Boolean, default: () => false },
    watchModelProp: { type: Boolean, default: () => false },
    selectedLabelFormatter: { type: Function, default: null },
    selectedValueFormatter: { type: Function, default: (v) => v },
    beforeOpen: { type: Function, default: null },
  },
  emits: ['opened', 'closed', 'search', 'select', 'clear'],
  setup(props, ctx) {
    const inputCtx = useBaseInput<string>(
      computed(() => props),
      ctx
    )
    const viewTools = useViewTools()
    const positioning = usePositioning()
    const isOpen = ref(false)
    const searchValue = ref<string>((inputCtx.model.value as string) || null)
    const autocompleteInputEl =
      ref<InstanceType<typeof HbAutocompleteInput>>(null)
    const closeOnOutsideClickListener = ref<() => void>(null)
    const rootClasses = computed(() => ({
      'hb-autocomplete': true,
      'hb-autocomplete--open': isOpen.value,
      'hb-autocomplete--small': props.isSmall,
      'hb-autocomplete--icon-only': props.isIconOnly,
      'hb-autocomplete--filled': !!inputCtx.model.value,
      'hb-autocomplete--invalid': inputCtx.isInvalid.value,
      'hb-autocomplete--disabled': inputCtx.isDisabled.value,
    }))
    const dropdownClasses = computed(() => ({
      'hb-autocomplete__dropdown': true,
      'hb-autocomplete__dropdown--top-level': props.topLevel,
      [props.uniqDropdownClass]: !!props.uniqDropdownClass,
    }))
    const rootListeners = computed(() => ({
      focusin: () => {
        if (!isOpen.value) {
          if (props.beforeOpen) {
            props.beforeOpen(() => open())
          } else {
            open()
          }
        }
      },
    }))

    const dropdownEl = ref<HTMLElement>(null)
    const emitOpened = () => ctx.emit('opened')
    const emitClosed = () => ctx.emit('closed')
    const emitSearch = () => ctx.emit('search', searchValue.value)
    const emitClear = () => ctx.emit('clear', null)
    const emitSelect = (value: unknown) => ctx.emit('select', value)
    const open = () => {
      positioning.setTargetStyle()
      isOpen.value = true
      emitOpened()
      autocompleteInputEl.value?.focus?.()

      if (process.client && !viewTools.isTablet.value) {
        closeOnOutsideClickListener.value = onClickOutside(
          positioning.referenceEl,
          () => close(),
          {
            ignore: [dropdownEl],
          }
        )
      }
    }
    const close = () => (isOpen.value = false)
    const select = (option: HbAcOption) => {
      searchValue.value = props.selectedLabelFormatter
        ? props.selectedLabelFormatter?.(option.value) || option.label
        : option.label
      inputCtx.model.value = props.selectedValueFormatter?.(option.value)
      emitSearch()
      emitSelect(option.value)
      close()
    }
    const onMobileSearchInit = (
      el: InstanceType<typeof HbAutocompleteInput>
    ) => {
      autocompleteInputEl.value = el
      positioning.referenceEl.value = el?.$el
    }

    watch(
      () => isOpen.value,
      (value, oldValue) => {
        if (value !== oldValue && !value) {
          emitClosed()
          closeOnOutsideClickListener.value?.()
        }
      }
    )

    if (props.watchModelProp) {
      watch(
        () => props.modelValue,
        (value) => {
          searchValue.value = value
        }
      )
    }

    return {
      rootClasses,
      rootListeners,
      isOpen,
      searchValue,
      dropdownEl,
      dropdownClasses,
      open,
      select,
      emitSearch,
      emitClear,
      onMobileSearchInit,
      ...inputCtx,
      ...positioning,
      ...viewTools,
    }
  },
}
</script>

<style lang="scss">
:root {
  --hb-autocomplete-radius: 10px;
  --hb-autocomplete-spinner-height: 21px;
  --hb-autocomplete-padding-x: 25px;
}

.autocomplete-placeholder {
  flex: 1;
  background: white;
  border-radius: 10px;

  @include mobile {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.hb-autocomplete {
  width: 100%;
  position: relative;
  transition: 0.2s all;
  will-change: transform;

  &__input {
    padding: 22px var(--hb-autocomplete-padding-x);
    padding-right: calc(
      var(--hb-autocomplete-padding-x) + var(--hb-autocomplete-spinner-height) +
        10px
    );
    border-radius: var(--hb-autocomplete-radius);
    background: var(--hb-white);
    width: 100%;
    height: 100%;
    font-size: 18px;
    border: none;
    outline: none !important;
    font-weight: 600;

    &,
    &::placeholder {
      color: var(--hb-blue1);
    }

    &::placeholder {
      font-weight: 500;
    }

    &--bordered {
      border: 2px solid var(--hb-gray4);
      border-radius: 10px;
    }

    @include tablet {
      height: 65px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__float-right {
    position: absolute;
    top: calc(50% - var(--hb-autocomplete-spinner-height) / 2);
    width: var(--hb-autocomplete-spinner-height);
    right: var(--hb-autocomplete-padding-x);

    @include mobile {
      right: calc(var(--hb-autocomplete-padding-x) + 25px);
    }
  }

  &__spinner {
    font-size: var(--hb-autocomplete-spinner-height);
    line-height: 0;
  }

  &__clear {
    line-height: 20px;
  }

  &__dropdown {
    @include scroll;
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    width: 100%;
    background: var(--hb-white);
    border-bottom-left-radius: var(--hb-autocomplete-radius);
    border-bottom-right-radius: var(--hb-autocomplete-radius);
    padding-top: 11px;
    padding-bottom: 20px;
    max-height: 370px;
    overflow: auto;
    z-index: 1;
    box-shadow: 0 30px 40px rgb(0, 0, 0, 0.07);

    &--top-level {
      z-index: 10;
      box-shadow: 0 20px 40px rgb(0, 0, 0, 0.2);
    }

    @include tablet {
      position: static;
      max-height: 100%;
      height: 100%;
      width: 100% !important;
    }
  }

  &--open {
    .hb-autocomplete__input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @include tablet {
        border-bottom-left-radius: 10px;
      }
    }
  }

  &--small {
    width: 100%;

    .hb-autocomplete__input {
      padding: 15px 50px 15px 20px;
      border: 1px solid var(--hb-gray3);
      border-radius: 10px;
      width: 100%;
      font-size: 16px;
    }

    .hb-autocomplete__float-right {
      right: calc(var(--hb-autocomplete-padding-x) + 25px);
    }

    .hb-autocomplete__clear {
      font-size: 14px;
    }
  }

  .autocomplete-fake-button {
    width: 100%;
    background-color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 25px;

    @include mobile {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--hb-blue1);
    }
  }

  &--icon-only {
    .hb-autocomplete__input {
      border: 0;
      padding: 0;
      text-indent: -9999px;
      width: 23px;
      height: 27px;
    }
  }

  &--invalid {
    .hb-autocomplete__input {
      border-color: var(--hb-red1) !important;
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    user-select: none;
    user-focus: none;

    .hb-autocomplete__input {
      background-color: rgba(#e1e6e8, 0.5);
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
  z-index: -1;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;

  @include mobile {
  }
}
</style>
