<template>
  <div
    class="hb-autocomplete__input-container"
    :class="{ 'hb-autocomplete__input-container--mobile': isMobile }"
  >
    <input
      ref="inputEl"
      v-model="model"
      class="hb-autocomplete__input"
      :class="{ 'hb-autocomplete__input--bordered': bordered }"
      type="text"
      :placeholder="placeholder"
      @input="emitSearch"
    />
    <spinner
      v-if="loading"
      class="hb-autocomplete__float-right hb-autocomplete__spinner"
    />
    <hb-raw-btn
      v-else-if="!!model"
      class="hb-autocomplete__float-right hb-autocomplete__clear"
      @click.prevent.stop="clear()"
    >
      <nuxt-icon name="close" filled></nuxt-icon>
    </hb-raw-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { ModelMixin, useModel } from 'wue'
import Spinner from '~/components/loaders/Spinner.vue'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import { useViewTools } from '~/composables'

export default defineComponent({
  name: 'HbAutocompleteInput',
  components: { HbRawBtn, Spinner },
  mixins: [ModelMixin],
  props: {
    placeholder: { type: String, default: null },
    loading: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
  },
  emits: ['search', 'clear'],
  setup(props, ctx) {
    const modelCtx = useModel<string>(props, ctx, { initialValue: null })
    const inputEl = ref<HTMLInputElement | null>(null)
    const viewTools = useViewTools()
    const emitSearch = () => ctx.emit('search', modelCtx.model.value)
    const emitClear = () => ctx.emit('clear')
    const focus = () => inputEl.value?.focus?.()
    const clear = () => {
      modelCtx.model.value = null
      focus()
      emitClear()
    }

    onMounted(() => {
      if (props.autoFocus && viewTools.isTablet.value) {
        focus()
      }
    })

    return {
      inputEl,
      focus,
      emitSearch,
      clear,
      ...modelCtx,
    }
  },
})
</script>

<style lang="scss">
.hb-autocomplete__input-container {
  position: relative;

  &--mobile {
    padding: 0 30px;
    margin-top: 20px;
  }
}
</style>
