<template>
  <component :is="tag" :class="rootClasses">
    <a v-if="!isMessage" href @click.prevent>
      <nuxt-icon v-if="iconName" :name="iconName" filled />
      <span>
        <slot></slot>
        <small v-if="error" class="hb-autocomplete-option__error">
          {{ error }}
        </small>
      </span>
    </a>
    <a v-else>
      <slot></slot>
    </a>
  </component>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbAutocompleteOption',
  props: {
    iconName: { type: String, default: () => null },
    pinned: { type: Boolean, default: () => false },
    isMessage: { type: Boolean, default: () => false },
    tag: { type: String, default: () => 'li' },
    error: { type: String, default: () => null },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'hb-autocomplete-option': true,
      'hb-autocomplete-option--pinned': props.pinned,
      'hb-autocomplete-option--message': props.isMessage,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.hb-autocomplete-option {
  list-style: none;

  a {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--hb-blue1);
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    padding: 11px 30px;

    .nuxt-icon {
      font-size: 24px;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__error {
    display: block;
    color: var(--hb-red1);
  }

  &--pinned {
    position: sticky;
    top: -11px;
    background: var(--hb-white);

    a {
      padding-bottom: 16px;
      border-bottom: 1px var(--hb-gray2) solid;
    }
  }

  &--message {
    a {
      text-align: center;
      display: block;
      font-weight: normal;
      padding-top: 25px;
    }
  }
}
</style>
