<template>
  <ul v-if="model" :style="targetStyle" :class="dropdownClasses">
    <slot name="prepend"></slot>
    <template v-if="options?.length || !isDirty">
      <hb-autocomplete-option
        v-for="(option, index) in options"
        :key="index"
        :icon-name="optionIconName"
        :pinned="option.pinned ?? false"
        @click="emitSelect(option)"
      >
        {{ option.label }}
      </hb-autocomplete-option>
    </template>
    <hb-autocomplete-option v-else-if="!loading" is-message>
      No results found
    </hb-autocomplete-option>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ModelMixin, useModel } from 'wue'
import HbAutocompleteOption from '~/components/base/form/HbAutocompleteOption.vue'
import type { HbAcOption } from '~/types'

export default defineComponent({
  name: 'HbAutocompleteDropdown',
  components: { HbAutocompleteOption },
  mixins: [ModelMixin],
  props: {
    options: { type: Array as () => HbAcOption[], default: () => [] },
    isDirty: { type: Boolean, default: false },
    targetStyle: { type: Object, default: () => ({}) },
    dropdownClasses: { type: Object, default: () => ({}) },
    optionIconName: { type: String, default: null },
    loading: { type: Boolean, default: false },
  },
  emits: ['select'],
  setup(props, ctx) {
    const modelCtx = useModel(props as any, ctx as any, { initialValue: false })
    const emitSelect = (option: HbAcOption) => ctx.emit('select', option)

    return {
      emitSelect,
      ...modelCtx,
    }
  },
})
</script>

<style scoped lang="scss"></style>
